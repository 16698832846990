import "react-toastify/dist/ReactToastify.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ArticlePageFactory } from "../factories/pages/articles";
import { LoginFactory } from "../factories/pages/login";
import { EditProjectFactory, CreateProjectFactory, ProjectListFactory, SingleProjectFactory } from "../factories/pages/index";
import { RegisterFactory } from "../factories/pages/register";
import { ToastContainer } from "react-toastify";
import { ThemeProvider } from "@emotion/react";
import { theme } from "../../application/global/e-styles";
import useWindowSize from "../../application/hooks/useScreenSize";
import Header from "../../presentation/components/header/header";
import Home from "../../presentation/pages/home/home";
import AboutPage from "../../presentation/pages/about/about";
import ArticlesPage from "../../presentation/pages/article/list";
import BottomNav from "../../presentation/components/header/bottom-nav";
import NotFound from "../../presentation/pages/error/not-found";
import AdminRouter from "./admin-router";



const Router: React.FC = () => {
  const { width, height } = useWindowSize();
  return (
    <BrowserRouter>
      <Header mobile={width != undefined ? width < 600 : false} />
      <ThemeProvider theme={theme}>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/e-melo" element={<Home />} />
          <Route path="/artigos" element={<ArticlesPage />} />
          <Route path="/artigos/:id/:slug" element={<ArticlePageFactory />} />
          <Route path="/projetos/id/:id" element={<SingleProjectFactory />} />
          <Route path="/projetos/create" element={<AdminRouter children={<CreateProjectFactory />}/>} />
          <Route path="/projetos/edit/:id" element={<AdminRouter children={<EditProjectFactory />}/>} />
          <Route path="/projetos" element={<ProjectListFactory />} />
          <Route path="/sobre" element={<AboutPage />} />
          <Route path="/login" element={<LoginFactory />} />
          <Route path="/register" element={<RegisterFactory />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
        {width && width < 600 && <BottomNav />}
      </ThemeProvider>
      <ToastContainer position="bottom-right" />
    </BrowserRouter>
  );
};

export default Router;
