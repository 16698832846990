import { useState, useEffect } from "react";
import { Box, Typography, TextField, Button } from "@mui/material";
import { toast } from "react-toastify";
import { useUserStore } from "../../states/user-store";
import { useNavigate, useParams } from "react-router-dom";
import { PostProject } from "../../../domain/usecases/project/post-project";
import { GetProject } from "../../../domain/usecases/project/get-project";
import CloseIcon from "@mui/icons-material/Close";

type Props = {
  editProject: PostProject;
  getProject: GetProject;
  projectId: string;
};

const EditProjectPage: React.FC<Props> = ({
  getProject,
  editProject,
  projectId,
}: Props) => {
  const [form, setForm] = useState({
    id: "",
    title: "",
    description: "",
    screenshots: [] as string[],
    contributors: [] as string[],
    url: "",
    tags: [] as string[],
  });

  const { user } = useUserStore();
  const navigate = useNavigate();

  const handleForm = (e: any) => {
    const { name, value } = e.target;
    switch (name) {
      case "contributors":
      case "tags":
        setForm({ ...form, [name]: value.split(",") });
        break;
      default:
        setForm({ ...form, [name]: value });
        break;
    }
  };

  const handleSubmit = async () => {
    const response = await editProject.update({
      ...form,
      owner: user.name,
      contributors: form.contributors
        .map((c) => c.trim())
        .filter((c) => c.length > 0),
      tags: form.tags.map((t) => t.trim()).filter((t) => t.length > 0),
    });

    if (!response || response <= 0) {
      toast.error("Erro ao editar projeto!");
      return;
    }

    toast.success("Projeto editado com sucesso!");
    navigate("/projetos");
  };

  const handleAddScreenshot = (e: any) => {
    const file = e.target.files?.[0];
    if (!file) return;
    const reader = new FileReader();
    reader.onload = (e) => {
      setForm({
        ...form,
        screenshots: [...form.screenshots, e.target?.result as string],
      });
    };
    reader.readAsDataURL(file);
  };

  const handleRemoveScreenshot = (index: number) => {
    var newScreenshots = [...form.screenshots];

    newScreenshots.splice(index, 1);

    setForm({ ...form, screenshots: newScreenshots });
  };

  const loadProject = async () => {
    const project = await getProject.get({
      id: projectId,
    });
    if (!project) {
      toast.error("Erro ao carregar projeto!");
      return;
    }
    setForm({
      id: project._id,
      title: project.title,
      description: project.description,
      screenshots: project.screenshots || [],
      contributors: project.contributors || [],
      tags: project.tags || [],
      url: project.url || "",
    });
  };

  useEffect(() => {
    loadProject();
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: "1rem",
        padding: "1em",
        width: "100%",
        height: "100%",
      }}
    >
      <Typography variant="h3">Edit Project</Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "1rem",
          width: "100%",
          maxWidth: "500px",
        }}
      >
        <TextField
          label="Title"
          variant="outlined"
          name="title"
          value={form.title}
          onChange={handleForm}
        />
        <TextField
          label="Description"
          variant="outlined"
          name="description"
          value={form.description}
          onChange={handleForm}
          multiline={true}
          rows={20}
        />
        <TextField
          label="Contributors"
          variant="outlined"
          name="contributors"
          value={form.contributors}
          onChange={handleForm}
        />
        <TextField
          label="Url"
          variant="outlined"
          name="url"
          value={form.url}
          onChange={handleForm}
        />
        <TextField
          label="Tags"
          variant="outlined"
          name="tags"
          value={form.tags}
          onChange={handleForm}
        />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
          }}
        >
          <Typography variant="h6">Screenshots</Typography>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              alignItems: "center",
            }}
          >
            <input
              type="file"
              accept="image/*"
              style={{
                display: "none",
              }}
              id="file"
              onChange={handleAddScreenshot}
            />
            <Button
              variant="outlined"
              color="primary"
              component="label"
              htmlFor="file"
            >
              Adicionar
            </Button>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "1rem",
            }}
          >
            {form.screenshots.map((screenshot, index) => (
              <Box
                sx={{
                  display: "flex",
                  position: "relative",
                }}
              >
                <CloseIcon
                  sx={{
                    position: "absolute",
                    color: "white",
                    backgroundColor: "RGBA(0, 0, 0, 0.5)",
                    right: 0,
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    handleRemoveScreenshot(index);
                  }}
                />
                <img
                  key={index}
                  src={screenshot}
                  alt={`Screenshot ${index}`}
                  style={{ maxWidth: "100%", borderRadius: "5px" }}
                />
              </Box>
            ))}
          </Box>
        </Box>
        <Button
          type="submit"
          variant="outlined"
          color="primary"
          onClick={handleSubmit}
        >
          Atualizar
        </Button>
      </Box>
    </Box>
  );
};

export default EditProjectPage;
