import React from "react";
import { Project } from "../../../../domain/entities/project";
import {
  Typography,
  Card,
  CardContent,
  CardMedia,
  CardActionArea,
  CardActions,
  Button,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import TagsRow from "../tag-list";

type Props = {
  project: Project;
  admin: boolean;
};

const ProjectCard: React.FC<Props> = ({ project, admin }) => {
  const navigate = useNavigate();

  const goToProject = () => {
    navigate(`/projetos/id/${project._id}`);
  };

  const renderText = (description: string, max: number) => {
    if (description && description.length > max) {
      return description.slice(0, max) + "...";
    }

    return description;
  };

  return (
    <Card
      onClick={goToProject}
      sx={{
        height: "320px",
      }}
    >
      <CardActionArea>
        <CardMedia
          component="img"
          height="140"
          image={
            project.screenshots[0] ||
            "https://fakeimg.pl/600x400?text==/&font=bebas"
          }
          alt={project.title}
        />
        <CardContent>
          <Typography gutterBottom variant="h5" component="div">
            {renderText(project.title, 20)}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {renderText(project.description, 100)}
          </Typography>
        </CardContent>
      </CardActionArea>
      <CardActions sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '0.5rem',
      }}>
        {admin && (
          <Button size="small" onClick={goToProject}>
            Ver mais
          </Button>
        )}
        {project.tags.length > 0 && <TagsRow tagList={project.tags} />}
      </CardActions>
    </Card>
  );
};

export default ProjectCard;
