import { Box } from "@mui/material";
import { Link } from "react-router-dom";

const NotAuthorized = () => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        gap: "1rem",
        margin: 0,
        minHeight: "70vh",
      }}
    >
      <h1>401</h1>
      <h2>Não autorizado</h2>
      <Link to="/">Voltar para a página inicial</Link>
    </Box>
  );
};

export default NotAuthorized;