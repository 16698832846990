// this route will only be available if the user is logged in and is an admin

import NotAuthorized from "../../presentation/pages/error/not-authorized";
import { useUserStore } from "../../presentation/states/user-store"

type AdminRouterProps = {
  children: React.ReactNode;
}

const AdminRouter: React.FC<AdminRouterProps> = ({children}) => {
  const { user } = useUserStore();

  if (!user || !user.admin) {
    return <NotAuthorized />;
  }

  return <>{children}</>;
}

export default AdminRouter;