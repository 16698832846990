import { HttpPostClient } from "../../protocols/http/http-post-client";
import { PostProject } from "../../../domain/usecases/project/post-project";
import { Project } from "../../../domain/entities/project";
import { AppConfig } from "../../../main/appconfig";

export class RemotePostProject implements PostProject {
  constructor(
    private readonly url: string,
    private readonly httpPostClient: HttpPostClient
  ) {}

  async post({
    title,
    description,
    owner,
    contributors,
    screenshots,
    tags,
    url
  }: PostProject.Params): Promise<Project> {
    try {
      const httpResponse = await this.httpPostClient.post({
        url: this.url,
        headers: {
          "Content-type": "application/json",
          "Authorization": `Bearer ${AppConfig.API_TOKEN}`
        },
        body: {
          title,
          description,
          owner,
          contributors,
          screenshots,
          url,
          tags
        }
      });

      const projectObj = httpResponse?.data
        ? httpResponse.data
        : null;

      return projectObj;
    } catch(error) {
      throw new Error("Erro ao publicar projeto");
    }
  }

  async update({
    id,
    title,
    description,
    owner,
    contributors,
    screenshots,
    tags,
    url
  }: PostProject.UpdateParams): Promise<number> {
    try {
      const apiToken = AppConfig.API_TOKEN;
      const httpResponse = await this.httpPostClient.put({
        url: `${this.url}/${id}`,
        headers: {
          "Content-type": "application/json",
          "Authorization": `Bearer ${apiToken}`
        },
        body: {
          id,
          title,
          description,
          owner,
          contributors,
          screenshots,
          tags,
          url
        }
      });

      const modifiedCount = httpResponse?.data?.modifiedCount || 0;

      return modifiedCount;
    } catch(error) {
      throw new Error("Erro ao atualizar projeto");
    }
  }
  
}
